
import { Component, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import {
    apiMemberPriceLists,
    apiMemberPriceJoin,
    apiMemberPriceQuit,
    apiMemberPriceOtherLists
} from '@/api/marketing/member_price'

@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class memberPrice extends Vue {
    apiMemberPriceLists = apiMemberPriceLists

    selectIds: any[] = []

    get disabledBtn() {
        return !this.selectIds.length
    }

    // 表单数据
    formData = {
        name: '', // 商品名称
        status: '', // 商品状态:1-销售中，0-仓库中
        goods_type: '', // 商品类型：1-实物商品，2-虚拟商品
        discount: '' // 参与折扣：1-是；0-否
    }

    statusList = [] // 状态列表
    typeList = [] // 类型列表
    discountList = [] // 折扣状态列表

    // 分页查询
    pager: RequestPaging = new RequestPaging()

    // 搜索
    onSearch() {
        this.pager.page = 1
        this.getMemberPriceList()
    }

    // 重置搜索
    resetSearch() {
        Object.keys(this.formData).map(key => {
            this.$set(this.formData, key, '')
        })
        this.getMemberPriceList()
    }

    // 获取会员折扣列表
    getMemberPriceList() {
        this.pager
            .request({
                callback: apiMemberPriceLists,
                params: { ...this.formData }
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载！')
            })
    }

    // 获取其他列表
    getOtherLists() {
        apiMemberPriceOtherLists({}).then(res => {
            console.log('res', res)
            this.statusList = res.status_list
            this.typeList = res.type_list
            this.discountList = res.discount_list
        })
    }

    // 多选
    handleSelectionChange(val: any[]) {
        this.selectIds = val.map(item => item.id)
    }

    // 批量参与折扣
    handleBatchParticipate(goods_ids: any) {
        apiMemberPriceJoin({
            goods_ids: Array.isArray(goods_ids) ? goods_ids : this.selectIds
        }).then(() => {
            this.getMemberPriceList()
        })
    }

    // 批量不参与折扣
    handleBatchNoParticipate(goods_ids: any) {
        apiMemberPriceQuit({
            goods_ids: Array.isArray(goods_ids) ? goods_ids : this.selectIds
        }).then(() => {
            this.getMemberPriceList()
        })
    }

    created() {
        this.getMemberPriceList()
        this.getOtherLists()
    }
}
